
// export const baseUrl = "http://15.207.222.170:5000";
// export const baseUrl = "https://devservice.teaman.in";
// export const baseUrl = "http://127.0.0.1:5000";
export const baseUrl = "https://stagingbackend.teaman.in"; //staging
// export const baseUrl = "https://productionservice.teaman.in"; //production





