import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import style from "./Organization.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { IoSearch } from "react-icons/io5";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";

import {
  Modal,
  TextField,
  MenuItem,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Loader from "../Loader/Loader";

function Organization() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [hubName, setHubName] = useState("");
  const [hubDistrict, setHubDistrict] = useState("");
  const [hubCity, setHubCity] = useState("");
  const [hubPincode, setHubPincode] = useState("");
  const [hubLatitude, setHubLatitude] = useState("");
  const [hubLongitude, setHubLongitude] = useState("");
  const [apiDescription, setApiDescription] = useState("");
  const [orgSuccess, setOrgSuccess] = useState(false);
  const [orgFail, setOrgFail] = useState(false);
  const [orgDeletion, setOrgDeletion] = useState(false);
  const [orgUpdation, setOrgUpdation] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [hubView, setHubView] = useState(true);
  const [loading, setLoading] = useState(false);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [deleteItem, setDeleteItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);

  const validateNumber = (value) => {
    const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/;
    return value === "" || regex.test(value);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("access-token");
    if (!storedToken) {
      setIsLoggedIn(false);
      navigate("/login");
    } else {
      setIsLoggedIn(true);
      fetchData();
    }
  }, [navigate]);

  async function userSearch(searchValue) {
    const reqData = {
      search_value: searchValue,
    };
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/organization/search`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setFilteredItems(data.data);
          setHubView(true);
        } else {
          setHubView(false);
        }
      });
  }

  async function fetchData() {
    setLoading(true);
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/organizations/list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const data = await response.json();
      setItems(data.data);
      setFilteredItems(data.data);
    } catch (error) {
      console.error("Error fetching hub data:", error);
    }
    setLoading(false);
  }

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
    setHubName("");
    setDescription("");
    setStatus("");
    setHubDistrict("");
    setHubCity("");
    setHubPincode("");
    setHubLatitude("");
    setHubLongitude("");
  };

  const handleCloseCreateModal = () => setOpenCreateModal(false);

  const handleAddHub = async () => {
    try {
      const storedAdminId = localStorage.getItem("adminUserId");
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/organization/creation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          org_name: hubName,
          description: description,
          district: hubDistrict,
          city: hubCity,
          latitude: hubLatitude,
          longitude: hubLongitude,
          pincode: hubPincode,
          created_person: storedAdminId,
          source: "web",
          type: "web",
        }),
      });
      const data = await response.json();
      if (data.status === true) {
        setItems([...items, data.data]);
        fetchData();
        handleCloseCreateModal();
        setOrgSuccess(true);
      } else {
        console.error("Error adding hub:", data.description);
        setApiDescription(data.description);
        setOrgFail(true);
      }
    } catch (error) {
      console.error("Error adding hub:", error);
    }
  };

  const handleOpenEdit = (item) => {
    setOrgId(item.org_id);
    setHubName(item.org_name);
    setDescription(item.description);
    setStatus(item.status);
    setHubDistrict(item.district);
    setHubCity(item.city);
    setHubPincode(item.pincode);
    setHubLatitude(item.latitude);
    setHubLongitude(item.longitude)
    setOpenEdit(true);
  };

  const handleCloseEdit = () => setOpenEdit(false);

  // const handleSort = (key) => {
  //   let direction = "ascending";
  //   if (sortConfig.key === key && sortConfig.direction === "ascending") {
  //     direction = "descending";
  //   }
  //   setSortConfig({ key, direction });
  // };

  const orgUpdate = async () => {
    const storedToken = JSON.parse(localStorage.getItem("access-token"));
    const response = await fetch(`${baseUrl}/organization/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body: JSON.stringify({
        org_id: orgId,
        org_name: hubName,
        description: description,
        district: hubDistrict,
        city: hubCity,
        pincode: hubPincode,
        latitude: hubLatitude,
        longitude: hubLongitude,
        status: status,
        source: "web",
        type: "web",
      }),
    });
    const data = await response.json();
    if (data.status === true) {
      fetchData();
      handleCloseEdit();
      setOrgUpdation(true);
    } else {
      setApiDescription(data.description);
      setOrgFail(true);
    }
  };

  // const dynamicSortIcon = (key) => {
  //   if (sortConfig.key === key) {
  //     return sortConfig.direction === "ascending" ? (
  //       <MdArrowUpward />
  //     ) : (
  //       <MdArrowDownward />
  //     );
  //   }
  //   return null;
  // };

  const sortedItems = [...filteredItems].sort((a, b) => {
    const key = sortConfig.key;
    if (key === "status") {
      if (sortConfig.direction === "ascending") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    } else {
      if (sortConfig.direction === "ascending") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    }
  });

  const handleDelete = (item) => {
    setDeleteItem(item);
  };

  const confirmDelete = async () => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/organization/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          org_id: deleteItem.org_id,
        }),
      });
      const data = await response.json();
      if (data.status === true) {
        setItems((prevItems) =>
          prevItems.filter((prevItem) => prevItem.org_id !== deleteItem.org_id)
        );
        fetchData();
        setDeleteItem(null);
        setOrgDeletion(true);
      } else {
        console.error("Error deleting hub:", data.description);
        setApiDescription(data.description);
        setOrgFail(true);
      }
    } catch (error) {
      console.error("Error deleting hub:", error);
    }
  };

  return (
    <div className={style.mainBox}>
      {isLoggedIn && (
        <div className={style.container}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={orgSuccess || orgDeletion || orgUpdation}
            sx={{ width: "20rem" }}
            autoHideDuration={3000}
            onClose={() => {
              setOrgSuccess(false);
              setOrgDeletion(false);
              setOrgUpdation(false);
            }}
          >
            <Alert
              severity="success"
              variant="filled"
              sx={{ width: "100%", fontSize: "1.15rem" }}
            >
              {orgSuccess
                ? "Hub created successfully"
                : orgDeletion
                ? "Hub deleted successfully"
                : orgUpdation
                ? "Hub updated successfully"
                : ""}
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={orgFail}
            sx={{ width: "20rem" }}
            autoHideDuration={3000}
            onClose={() => {
              setOrgFail(false);
            }}
          >
            <Alert
              variant="filled"
              severity="error"
              sx={{ width: "100%", fontSize: "1.15rem" }}
            >
              {apiDescription}
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={validationFail}
            sx={{ width: "15rem" }}
            autoHideDuration={3000}
            onClose={() => {
              setValidationFail(false);
            }}
          >
            <Alert
              variant="filled"
              severity="error"
              sx={{ width: "100%", fontSize: "1.15rem" }}
            >
              {/* {errorMessage} */}
            </Alert>
          </Snackbar>

          <div className={style.Header}>
            <div className={style.HeaderText}>
              <p>Hub Management</p>
            </div>

            <div className={style.search}>
              <IoSearch className={style.searchIcon} />
              <input
                type="text"
                placeholder="search"
                className={style.searchInput}
                value={searchValue}
                onChange={(e) => {
                  const searchInputValue = e.target.value;
                  setSearchValue(searchInputValue);
                  if (searchInputValue !== "") {
                    userSearch(searchInputValue);
                  } else {
                    fetchData();
                    setHubView(true);
                  }
                }}
              />
            </div>
            <button onClick={handleOpenCreateModal} className={style.HeadButton}>Add Hub</button>

          </div>

          {/* <div className={style.HeadButton}>
            <button onClick={handleOpenCreateModal}>Add Hub</button>
          </div> */}

{loading ? (
              <Loader />
            ) : (
          <div className={style.tableContainer}>
            {hubView ? (
              <TableContainer component={Paper} className={style.table}>

                <Table>
                  <TableHead className={style.tableHead}>
                    <TableRow>
                      <TableCell 
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "1rem",
                          fontWeight: 500,
                          lineHeight: "24px",
                          letterSpacing: "0.01em",
                          textAlign: "left",
                          color: "#000",
                        }}
                      >
                        {/* <TableSortLabel
                          active={sortConfig.key === "org_name"}
                          direction={
                            sortConfig.key === "org_name"
                              ? sortConfig.direction
                              : "asc"
                          }
                          onClick={() => handleSort("org_name")}
                        >
                          Hub Name {dynamicSortIcon("org_name")}
                        </TableSortLabel> */}
                        Hub Name
                      </TableCell>
                      <TableCell 
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "1rem",
                          fontWeight: 500,
                          lineHeight: "24px",
                          letterSpacing: "0.01em",
                          textAlign: "left",
                          color: "#000",
                        }}
                      >Description</TableCell>
                      <TableCell 
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "1rem",
                          fontWeight: 500,
                          lineHeight: "24px",
                          letterSpacing: "0.01em",
                          textAlign: "left",
                          color: "#000",
                        }}
                      >
                        {/* <TableSortLabel
                          active={sortConfig.key === "status"}
                          direction={
                            sortConfig.key === "status"
                              ? sortConfig.direction
                              : "asc"
                          }
                          onClick={() => handleSort("status")}
                        >
                          Status {dynamicSortIcon("status")}
                        </TableSortLabel> */}
                        Status
                      </TableCell>
                      <TableCell 
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "1rem",
                          fontWeight: 500,
                          lineHeight: "24px",
                          letterSpacing: "0.01em",
                          textAlign: "left",
                          color: "#000",
                        }}
                      >Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedItems.map((item) => (
                      <TableRow key={item.org_id}>
                        <TableCell>{item.org_name}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>
                          <div className={style.actionIcons}>
                            <div className={style.editbtn}>
                              <BiEditAlt
                                onClick={() => handleOpenEdit(item)}
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                title="Edit"
                              />
                            </div>
                            <div className={style.deletebtn}>
                              <RiDeleteBinLine
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                onClick={() => handleDelete(item)}
                                title="Delete"
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Alert severity="error" color="warning">
                No HUB found..!
              </Alert>
            )}
          </div>
           )}

          <Modal
            open={openCreateModal}
            onClose={handleCloseCreateModal}
            aria-labelledby="add-hub-modal"
            aria-describedby="add-hub-form"
          >
            <Paper className={style.modal} elevation={3}>
              <h2 id="add-hub-modal">Add Hub</h2>
              <div className={style.modalContent}>
                <TextField
                  label="HUB Name"
                  value={hubName}
                  onChange={(e) => setHubName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                />
                <TextField
                  label="District"
                  value={hubDistrict}
                  onChange={(e) => setHubDistrict(e.target.value)}
                  fullWidth
                  multiline
                  margin="normal"
                />
                <div className={style.textFieldGroup}>
                    <TextField
                      label="City"
                      value={hubCity}
                      onChange={(e) => setHubCity(e.target.value)}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                    <TextField
                      label="ZIP Code"
                      value={hubPincode}
                      // onChange={(e) => setHubPincode(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setHubPincode(value);
                        }
                      }}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                  </div>
                  <div className={style.textFieldGroup}>
                    <TextField
                      label="Latitude"
                      value={hubLatitude}
                      // onChange={(e) => setHubLatitude(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setHubLatitude(value);
                        }
                      }}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                    <TextField
                      label="Longitude"
                      value={hubLongitude}
                      // onChange={(e) => setHubLongitude(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setHubLongitude(value);
                        }
                      }}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                  </div>

                <div className={style.modalActions}>
                  <button
                    className={style.modalSub}
                    variant="contained"
                    onClick={handleAddHub}
                  >
                    SUBMIT
                  </button>
                  <button
                    className={style.modalClose}
                    variant="outlined"
                    onClick={handleCloseCreateModal}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </Paper>
          </Modal>

          <Modal
            open={deleteItem !== null}
            onClose={() => setDeleteItem(null)}
            aria-labelledby="delete-hub-modal"
            aria-describedby="delete-hub-form"
          >
            <Paper className={style.modal} elevation={3}>
              <h2 id="delete-hub-modal">Confirm Delete</h2>
              <div className={style.modalContent}>
                <p className={style.deleteContent}>Are you sure you want to delete {deleteItem?.org_name}?</p>
                <div className={style.modalActions}>
                  <button
                    className={style.delconf}
                    variant="contained"
                    onClick={confirmDelete}
                  >
                    Confirm
                  </button>
                  <button
                    className={style.delcancel}
                    variant="outlined"
                    onClick={() => setDeleteItem(null)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Paper>
          </Modal>

          <Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="edit-hub-modal"
            aria-describedby="edit-hub-form"
          >
            <Paper className={style.modal} elevation={3}>
              <h2 id="edit-hub-modal">Edit Hub</h2>
              <div className={style.modalContent}>
                <TextField
                  label="HUB Name"
                  value={hubName}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setHubName(e.target.value)}
                />
                <TextField
                  label="Description"
                  value={description}
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                  label="District"
                  value={hubDistrict}
                  onChange={(e) => setHubDistrict(e.target.value)}
                  fullWidth
                  multiline
                  margin="normal"
                />
                <div className={style.textFieldGroup}>
                    <TextField
                      label="City"
                      value={hubCity}
                      onChange={(e) => setHubCity(e.target.value)}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                    <TextField
                      label="ZIP Code"
                      value={hubPincode}
                      // onChange={(e) => setHubPincode(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setHubPincode(value);
                        }
                      }}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                  </div>
                  <div className={style.textFieldGroup}>
                    <TextField
                      label="Latitude"
                      value={hubLatitude}
                      // onChange={(e) => setHubLatitude(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setHubLatitude(value);
                        }
                      }}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                    <TextField
                      label="Longitude"
                      value={hubLongitude}
                      // onChange={(e) => setHubLongitude(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setHubLongitude(value);
                        }
                      }}
                      fullWidth
                      margin="normal"
                      // style={{ width: '48%' }}
                    />
                  </div>
                <TextField
                  select
                  label="Status"
                  value={status}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </TextField>

                <div className={style.modalActions}>
                  <button
                    className={style.EditSub}
                    variant="contained"
                    onClick={orgUpdate}
                  >
                    SUBMIT
                  </button>
                  <button
                    className={style.EditClose}
                    variant="outlined"
                    onClick={handleCloseEdit}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </Paper>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Organization;
