import React, { useState, useEffect, useRef } from "react";
import style from "./Delivery.module.css";
import { IoSearch, IoFilter } from "react-icons/io5";
import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Box,
  Autocomplete,
  TextField,
  Grid,
  IconButton,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { useOutletContext } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import Loader from "../Loader/Loader";

const Delivery = () => {
  const { selectedNewHub } = useOutletContext();

  const navigate = useNavigate();
  const sortRef = useRef(null);

  const token = JSON.parse(localStorage.getItem("access-token"));

  const [deliveryData, setDeliveryData] = useState([]);
  const [filterMenuItem, setFilterMenuItem] = useState([]);
  const [sortValueId, setSortValueId] = useState(0);
  const [sortListShow, setSortListShow] = useState(false);
  const [searchValue, setSearchVAlue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [deliveryIdForMap, setDeliveryIdForMap] = useState("");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const convertDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const handleSortValue = (value) => {
    setSortValueId(value);
    deliveryList(selectedNewHub, searchValue, fromDate, toDate, value);
  };

  async function filterItems() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/progress/status/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body: JSON.stringify({
        order: "delivery order",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const updatedData = [
          { status_code: 0, status_name: "None" },
          ...data.data,
        ];
        setFilterMenuItem(updatedData);
      });
  }

  async function listItemAssets() {
    await fetch(`${baseUrl}/list/item/assets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hub_id: selectedNewHub,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "asset list");
        if (data.status === true) {
          setAssetData(data.data);
          setModalOpen(true);
          console.log(data.data, "assetData");
        }
      });
  }

  async function deliveryAssetsMap() {
    const reqData = {
      delivery_order_id: deliveryIdForMap,
      asset_id: selectedAssets.map((asset) => asset.itemID),
    };
    await fetch(`${baseUrl}/delivery/order/assets/map`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "asset list");
        if (data.status === true) {
          setDeliveryData(data.data);
        }
      });
  }

  async function deliveryList(hubId, search, from, to, sort) {
    if (from && !to) {
      to = new Date().toISOString().split("T")[0]; // Set current date to t_date in YYYY-MM-DD format
      setToDate(to);
    }
    const body = {
      hub_id: hubId ? hubId : "",
      search: search ? search : "",
      from_date: from ? from : "",
      to_date: to ? to : "",
      sort: sort ? sort : 0,
    };
    setLoading(true);
    await fetch(`${baseUrl}/delivery/order/lists`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "delivery");
        if (data.status === true) {
          setDeliveryData(data.data);
        }else{
          setDeliveryData([]);
        }
      })
      .finally(() => setLoading(false)); 
  }

  useEffect(() => {
    if (selectedNewHub) {
      deliveryList(selectedNewHub);
      filterItems();
    }
  }, [selectedNewHub]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortListShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{
          backgroundColor: "#f7eedf",
          height: { xs: "auto", sm: "7rem" },
          padding: { xs: "1rem", md: "0" },
        }}
      >
        <Grid item xs={12} sm={4} md={3} lg={1}>
          <Typography
            variant="h5"
            sx={{
              color: "#59293a",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: { xs: 20, sm: 24 },
              pl: { xs: 2, sm: 4 },
            }}
          >
            Delivery
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={9}
          lg={11}
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm={4} md={3} lg={5} container spacing={2}>
            <Grid item xs={6} lg={6} sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem", 
            }}>
            <label>From </label>
              <input
                type="date"
                placeholder=""
                className={style.dateInput}
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)
                  if (newFromDate !== "") {
                    deliveryList(selectedNewHub, searchValue, newFromDate, toDate)
                  } else {
                    deliveryList(selectedNewHub, searchValue, "", toDate)
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} lg={6} sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem", 
            }}
            >
              <label>To</label>
              <input
                type="date"
                placeholder=""
                className={style.dateInput}
                value={toDate}
                onChange={(e) => {
                  const newToDate = e.target.value
                  setToDate(newToDate)
                  if (newToDate !== "") {
                    deliveryList(selectedNewHub, searchValue, fromDate, newToDate)
                  } else {
                    deliveryList(selectedNewHub, searchValue, fromDate, "")
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={6} sm={3} md={2} lg={0.5} sx={{ position: "relative" }}>
            <IconButton
              onClick={(e) => {
                setSortListShow(!sortListShow);
                e.stopPropagation();
              }}
              sx={{ color: "#59293a", fontSize: "1.5rem" }}
            >
              <IoFilter />
            </IconButton>

            {sortListShow && (
              <Box
                ref={sortRef}
                sx={{
                  position: "absolute",
                  top: { xs: "4rem", sm: "3rem" }, // Adjusts based on screen size
                  backgroundColor: "#fff",
                  borderRadius: 1,
                  boxShadow: 3,
                  p: 1,
                  width: "11rem",
                  height: "auto",
                  zIndex: 9999,
                }}
              >
                {filterMenuItem.map((list, i) => (
                  <Box
                    key={i}
                    onClick={() => handleSortValue(list.status_code)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                      padding: "0.5rem",
                      borderRadius: 1,
                      backgroundColor:
                        sortValueId === list.status_code
                          ? "#ddd"
                          : "transparent",
                    }}
                  >
                    {sortValueId === list.status_code && <FaCheck />}
                    <Typography>{list.status_name}</Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>

          <Grid item xs={11} sm={5} md={4} lg={3}>
            <div className={style.search}>
              <IoSearch className={style.searchIcon} />
              <input
                type="text"
                placeholder=""
                value={searchValue}
                onChange={(e) => {
                  const newValue = e.target.value
                  setSearchVAlue(newValue);
                  if (newValue !== "") {
                    deliveryList(selectedNewHub, newValue, fromDate, toDate)
                  } else {
                    deliveryList(selectedNewHub)
                  }

                }}
                className={style.searchInput} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
              <Loader />
            ) : (
      <Box sx={{ p: 2, overflowX: "auto" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "35rem" }}>
          {deliveryData.length > 0 ? (
            <Table aria-label="delivery table">
              <TableHead
                sx={{ backgroundColor: "#f7eedf", position: "sticky", top: 0 }}
              >
                <TableRow>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Order Number</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Customer</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Amount</TableCell>
                  <TableCell style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Delivery Date</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Address</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Delivery status</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Staff</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deliveryData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">
                      {data.delivery_order_number}
                    </TableCell>
                    <TableCell align="left">{data.customer_name}</TableCell>
                    <TableCell align="left">{data.net_amount.toFixed(2)}</TableCell>
                    <TableCell component="th" scope="row">
                      {convertDateFormat(data.order_date.$date)}
                    </TableCell>
                    <TableCell align="left">
                      {data.customer_address}
                    </TableCell>
                    <TableCell align="left">{data.status_name}</TableCell>
                    <TableCell align="left">
                      {data.staff_details?.firstname}
                    </TableCell>
                    <TableCell align="left">
                      <button
                        className={style.detailButton}
                        onClick={() => {
                          navigate("/delivery/details", {
                            state: {
                              delivery_id: data.delivery_order_id,
                            },
                          });
                        }}
                      >
                        Details
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Alert
              severity="warning"
              sx={{ color: "#59293a", fontWeight: 500 }}
            >
              No orders found..!
            </Alert>
          )}
        </TableContainer>
      </Box>
       )}
      <Modal
        open={modalOpen}
        aria-labelledby="create-order-modal"
        aria-describedby="create-order-form"
      >
        <Paper className={style.modal} elevation={6}>
          <h2 id="create-order-modal">Map Assets</h2>
          <div className={style.modalContent}>
            <Box sx={{ minWidth: 200 }}>
              <Autocomplete
                multiple
                id="asset-select"
                options={assetData}
                getOptionLabel={(option) => option.ItemName}
                value={selectedAssets}
                onChange={(event, newValue) => {
                  setSelectedAssets(newValue); // Update with entire object
                }}
                isOptionEqualToValue={(option, value) =>
                  option.itemID === value.itemID
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an Asset"
                    variant="outlined"
                    size="small"
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&:hover fieldset": {
                        borderColor: "#794a57",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#a96b84",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <div className={style.modalActions}>
              <button
                variant="contained"
                onClick={() => {
                  setModalOpen(false);
                  deliveryAssetsMap();
                }}
                className={style.EditSub}
              >
                Confirm
              </button>
              <button
                variant="outlined"
                onClick={() => {
                  setModalOpen(false);
                }}
                className={style.EditClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Paper>
      </Modal>
    </Box>
  );
};

export default Delivery;
