import React, { useEffect, useState } from "react";
import style from "./Customer.module.css";
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IoSearch } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { useNavigate } from "react-router-dom";
import { LuLink } from "react-icons/lu";
import Loader from "../Loader/Loader";

function Customer() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerGroupId = queryParams.get("customerGroupId");
  const selectedLocationId = queryParams.get("selectedLocationId");
  const { selectedGroupId } = location.state || {};

  const token = JSON.parse(localStorage.getItem("access-token"));
  const navigate = useNavigate();

  const [customerGroupData, setCustomerGroupData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [deletePopup, setDeletePopup] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [roleFail, setRoleFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [customerSuccessSnack, setCustomerSuccessSnack] = useState(false);
  const [customerDeletionSnack, setCustomerDeletionSnack] = useState(false);
  const [customerUpdationSnack, setCustomerUpdationSnack] = useState(false);
  const [mappingSuccessSnack, setMappingSuccessSnack] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [customerView, setCustomerView] = useState(true);
  const [mapModal, setMapModal] = useState("");
  const [cIdForMap, setCIdForMap] = useState("");
  const [customerUnderGroup, setCustomerUnderGroup] = useState([]);
  const [unMapId, setUnMapId] = useState("");
  const [unMapPopup, setUnMapPopup] = useState(false);
  const [unMapName, setUnMapName] = useState("");
  const [filterType, setFilterType] = useState("All"); // New state for filter type
  const [loading, setLoading] = useState(false);

  async function customerGroupList(mapCustomerId) {
    await fetch(`${baseUrl}/not_mapped/groups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        customer_id: mapCustomerId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "customerData");
        if (data.status === true) {
          setCustomerGroupData(data.data);
        } else {
          setCustomerGroupData([]);
        }
      });
  }

  async function customerList() {
    setLoading(true);
    await fetch(`${baseUrl}/customer/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "customerDataList");
        if (data.status === true) {
          setCustomerData(data.data);
          if (data.data.length > 0) {
            setCustomerView(true);
          } else {
            setCustomerView(false);
          }
        }
      })
      .finally(() => setLoading(false)); 
  }

  async function customerDelete() {
    await fetch(`${baseUrl}/customer/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        customer_id: deleteId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "deleteData");
        if (data.status === true) {
          setDeletePopup(false);
          // customerList();
          setCustomerDeletionSnack(true);
          if (searchValue) {
            customerSearch(searchValue);
          } else {
            customerList();
          }
        } else {
          setApiDescription(data.description);
          setRoleFail(true);
        }
      });
  }

  async function customerSearch(searchQuery) {
    const reqData = {
      search: searchQuery,
    };
    await fetch(`${baseUrl}/customer/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "deleteData");
        if (data.status === true) {
          setCustomerData(data.data);
          setCustomerView(true);
        } else {
          setCustomerData([]);
          setCustomerView(false);
        }
      });
  }

  async function mapCustomerGroup() {
    const reqData = {
      customer_id: cIdForMap,
      customergroup_id: selectedGroup,
    };
    await fetch(`${baseUrl}/customer/group/map`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "map Data");
        if (data.status === true) {
          setMappingSuccessSnack(true);
          setMapModal(false);
          setSelectedGroup([]);
        }
      });
  }

  async function customerListUnderGroup() {
    await fetch(`${baseUrl}/customers/under/group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        group_id: selectedGroupId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "customer under group");
        if (data.status === true) {
          setCustomerUnderGroup(data.data);
        }
      });
  }

  async function unMappingCustomer() {
    await fetch(`${baseUrl}/unmap/customers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mapped_id: unMapId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "unmapping customers");
        if (data.status === true) {
          setUnMapPopup(false);
          customerListUnderGroup();
        }
      });
  }

  useEffect(() => {
    console.log(customerUnderGroup, "customerUnderGroup");
    if (selectedGroupId) {
      customerListUnderGroup(selectedGroupId);
    } else {
      customerList();
    }
  }, [selectedGroupId]);

  const filteredCustomerData = customerData.filter((customer) => {
    if (filterType === "All") return true;
    return customer.customer_type === filterType;
  });

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          customerSuccessSnack ||
          customerDeletionSnack ||
          customerUpdationSnack ||
          mappingSuccessSnack
        }
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCustomerSuccessSnack(false);
          setCustomerDeletionSnack(false);
          setCustomerUpdationSnack(false);
          setMappingSuccessSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {customerSuccessSnack
            ? "Customer created successfully"
            : customerDeletionSnack
            ? "Customer deleted successfully"
            : customerUpdationSnack
            ? "Customer updated successfully"
            : mappingSuccessSnack
            ? "Mapping done successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={roleFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setRoleFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Customer</p>
        </div>

        <div className={style.dropdown}>
          <Box sx={{ minWidth: 200 }}>
            <FormControl sx={{ mr: 1, minWidth: 160 }} size="small">
              <Select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                displayEmpty
                input={
                  <OutlinedInput
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&fieldset:hover ": {
                        borderColor: "#794a57",
                      },
                      "& fieldset:active": {
                        borderColor: "#a96b84",
                      },
                    }}
                  />
                }
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="B2B">B2B</MenuItem>
                <MenuItem value="B2C">B2C</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className={style.search}>
          <IoSearch className={style.searchIcon} />
          <input
            type="text"
            placeholder="search"
            className={style.searchInput}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (e.target.value === "") {
                customerList();
              } else {
                customerSearch(e.target.value);
              }
            }}
          />
        </div>

        <button
          onClick={() => {
            navigate("/customers/customer/creation");
          }}
          className={style.buttonSection}
        >
          Add Customer
        </button>
      </div>

      {loading ? (
              <Loader />
            ) : ( 

      <div className={style.tableSection}>
        {customerView ? (
          <TableContainer component={Paper} className={style.table}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead
                style={{ backgroundColor: "#f7eedf" }}
                className={style.tableHead}
              >
                <TableRow>
                  <TableCell
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Source
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Created Date
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCustomerData.map((item) => (
                  <TableRow key={item.customer_id}>
                    <TableCell component="th" scope="row">
                      {item.customer_name}
                    </TableCell>
                    <TableCell align="left">{item.status}</TableCell>
                    <TableCell align="left">{item.source}</TableCell>
                    <TableCell align="left">
                      {(() => {
                        const date = new Date(item.created_time.$date);
                        const day = String(date.getDate()).padStart(2, "0");
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          "0"
                        ); // Months are zero-based
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`;
                      })()}
                    </TableCell>
                    <TableCell align="left">
                      <BiEditAlt
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        title="Edit"
                        onClick={() => {
                          navigate("/customers/customer/updation", {
                            state: {
                              editId: item.customer_id,
                              editName: item.customer_name,
                              editEmail: item.customer_email,
                              editPhone: item.customer_phone,
                              editStatus: item.status,
                              editBussinessName: item.bussiness_name,
                              editLatitude: item.latitude,
                              editLongitude: item.longitude,
                              editLocation: item.location,
                              editAddress: item.address,
                              editCity: item.city,
                              editState: item.state,
                              editCountry: item.country,
                              editZip: item.zip_code,
                              editGender: item.gender,
                              editDob: item.dob,
                              editTaxAcNo: item.tax_account_no,
                              editTaxClass: item.tax_classification,
                              editPriceLevel: item.price_level,
                              editCustomerGroup: item.customer_group,
                              editCustomerType: item.customer_type,
                              editHubName: item.hub_name
                            },
                          });
                        }}
                      />
                      <RiDeleteBinLine
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginLeft: "15px",
                        }}
                        title="Delete"
                        onClick={() => {
                          setDeletePopup(true);
                          setDeleteId(item.customer_id);
                          setDeleteName(item.customer_name);
                        }}
                      />
                      <LuLink
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginLeft: "15px",
                        }}
                        title="Map Customer Group"
                        onClick={() => {
                          setMapModal(true);
                          customerGroupList(item.customer_id);
                          setCIdForMap(item.customer_id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Alert severity="error" color="warning">
            No Customer found..!
          </Alert>
        )}
      </div>
       )}

      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>
                Are you sure you want to delete {deleteName}?
              </p>
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    customerDelete();
                  }}
                  className={style.EditSub}
                >
                  Confirm
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {mapModal && (
        <Modal
          open={mapModal}
          onClose={() => {
            setMapModal(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Select Customer Group</h2>
            <div className={style.modalContent}>
            {customerGroupData.length > 0 ? (
              <TextField
                select
                label="Select Customer Group"
                fullWidth
                margin="normal"
                value={selectedGroup}
                onChange={(e) => {
                  console.log("Selected group:", e.target.value);
                  setSelectedGroup(e.target.value);
                }}
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => (
                    <div className={style.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            customerGroupData.find(
                              (group) => group.customergroup_id === value
                            ).customer_group_name
                          }
                          className={style.chip}
                        />
                      ))}
                    </div>
                  ),
                }}
              >
                {customerGroupData.map((item) => (
                  <MenuItem
                    key={item.customergroup_id}
                    value={item.customergroup_id}
                  >
                    <Checkbox
                      checked={
                        selectedGroup.indexOf(item.customergroup_id) > -1
                      }
                    />
                    <ListItemText primary={item.customer_group_name} />
                  </MenuItem>
                ))}
              </TextField>
            ) : (
          <p className={style.noGroupMessage}>No groups available</p>
        )}
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    mapCustomerGroup();
                  }}
                  className={style.EditSub}
                >
                  Map
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setMapModal(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
      {unMapPopup && (
        <Modal
          open={unMapPopup}
          onClose={() => {
            setUnMapPopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Unmap</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>
                Are you sure you want to Unmap {unMapName}?
              </p>
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    unMappingCustomer();
                  }}
                  className={style.EditSub}
                >
                  Confirm
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setUnMapPopup(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
}

export default Customer;
