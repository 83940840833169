import React, { useEffect, useState } from "react";
import style from "./HubConfig.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { baseUrl } from "../Url";
import Loader from "../Loader/Loader";

const HubConfig = () => {
  const { selectedNewHub } = useOutletContext();
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [loading, setLoading] = useState(false);
  const [hubConfigData, setHubConfigData] = useState([]);

  async function hubConfigList() {
    const reqData = {
      hub_id: selectedNewHub,
    };
    setLoading(true);
    await fetch(`${baseUrl}/hub/config/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setHubConfigData(data.data);
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (selectedNewHub) {
      hubConfigList();
    }
  }, [selectedNewHub]);
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Hub Configuration</p>
        </div>
        {/* <div className={style.headerActions}>
          <button>Add Configuration</button>
        </div> */}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className={style.body}>
          <TableContainer className={style.table} component={Paper}>
            <Table aria-label="caption table">
              <TableHead className={style.tableHead}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Configuration Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Value
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hubConfigData && hubConfigData.length > 0 ? (
                  hubConfigData.map((data, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell align="left">
                          {data.hub_config_name}
                        </TableCell>
                        <TableCell align="left">
                          {data.hub_config_value}
                        </TableCell>
                        <TableCell align="left">
                          {data.hub_config_type}
                        </TableCell>
                        <TableCell align="left">
                          {data.hub_config_code}
                        </TableCell>
                        <TableCell align="left">
                          {data.hub_config_status}
                        </TableCell>

                        <TableCell align="left">
                          <BiEditAlt
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            title="Edit"
                          />
                          {/* <RiDeleteBinLine
                            style={{
                              fontSize: "20px",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            title="Delete"
                          /> */}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default HubConfig;
